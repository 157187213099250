<template>
  <div class='d-md-none'>
    <!-- Home -->
    <v-row class='home ma-0 mb-5'>
      <v-spacer />
      <v-col cols='12'
             md='5'>
        <v-row class='ml-0 mr-0 mb-0 mt-5'>
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column'>
            DOUCE PAPETERIE
          </v-col>

          <v-col cols='12'>
            <p class='body-text text-center'>
              -
              <br>
              Des illustrations poétiques, joyeuses et légères,
              sur des affiches, des faire-part, des cartes...
              <br>
              Des illustrations poétiques, joyeuses et légères,
              sur des affiches, des faire-part, des cartes...
              <br>
              Tout ce qu’il faut pour rêver un peu.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols='12'
             md='5'>
        <v-img :src='require(`@/assets/home_page/home_page_main.png`)' />
      </v-col>
      <v-spacer />
    </v-row>

    <!-- CALENDAR -->
    <v-row class='mt-10 ma-0'>
      <!-- IMG -->
      <v-col class='d-flex align-center justify-center pa-0'
             cols='12'
             md='4'>
        <router-link to='/products/643'
                     class='text-decoration-none'
                     style='width: 100%'>
          <v-img :src='require(`@/assets/home_page/automn.jpg`)'
                 contain />
        </router-link>
      </v-col>
      <!-- Texte -->
      <v-col class='d-flex align-center justify-center calendar-color pt-10 pb-10'
             cols='12'
             md='4'>
        <v-row>
          <!-- Titre -->
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column'>
            <!-- <v-img :src='require(`@/assets/home_page/calendar/ico.png`)' width='100' /> -->
            NOUVEAUTÉ
          </v-col>

          <!-- Texte -->
          <v-col cols='12'>
            <p class='body-text text-center'>
              <span class='text-h5'>-</span>
              <br>
              La plus belle des saisons est arrivée,
              <br>
              et ses nouvelles illustrations avec !
            </p>
          </v-col>

          <!-- BTN -->
          <v-col cols='12'
                 class='buttons-container mt-4'>
            <router-link to='/products/643'
                         class='text-decoration-none d-flex justify-center'>
              <v-btn text
                     elevation='0'
                     color='black'
                     class='buttons'
                     outlined>
                Découvrir
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- ILLUSTRATIONS -->
    <v-row class='mt-10 ma-0'>
      <v-spacer />
      <!-- IMG -->
      <v-col class='d-flex align-center justify-center pa-0'
             cols='12'
             md='4'>
        <router-link to='/categories/2'
                     class='text-decoration-none'
                     style='width: 100%'>
          <v-img :src='require(`@/assets/home_page/home_illustration_img.jpg`)'
                 aspect-ratio='1'
                 contain />
        </router-link>
      </v-col>
      <!-- Texte -->
      <v-col class='d-flex align-center justify-center pb-10'
             cols='12'
             md='4'>
        <v-row>
          <!-- Titre -->
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column'>
            <v-img :src='require(`@/assets/home_page/branch_ico.png`)'
                   width='14%' />
            ILLUSTRATIONS
          </v-col>

          <!-- Texte -->
          <v-col cols='12'>
            <p class='body-text text-center'>
              <span class='text-h5'>-</span>
              <br>
              Découvrez les illustrations imprimées,
              <br>
              de la carte postale à l’affiche A3,
              <br>
              il y en a pour tous les goûts!
              <br>
              <br>
              <span>NOUVEAU!</span>
              <br>
              Découvrez les nouvelles options de personnalisation
            </p>
          </v-col>

          <!-- BTN -->
          <v-col cols='12'
                 class='buttons-container mt-4'>
            <router-link to='/categories/2'
                         class='text-decoration-none d-flex justify-center'>
              <v-btn text
                     elevation='0'
                     color='black'
                     class='buttons'
                     outlined>
                Découvrir
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- SUR MESURE -->
    <v-row class='mt-10 ma-0'>
      <v-spacer />
      <!-- IMG -->
      <v-col cols='12'
             md='4'
             class='pa-0'>
        <router-link to='/categories/7'
                     class='text-decoration-none'
                     style='width: 100%'>
          <v-img :src='require(`@/assets/home_page/home_made_img.jpg`)'
                 aspect-ratio='1'
                 contain />
        </router-link>
      </v-col>
      <!-- Texte -->
      <v-col cols='12'
             md='4'
             class='d-flex align-center illustration pt-10 pb-10'>
        <v-row>
          <!-- Titre -->
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column'>
            <v-img :src='require(`@/assets/home_page/house_ico.png`)'
                   width='100' />
            <span class='white--text'>SUR MESURE</span>
          </v-col>

          <!-- Texte -->
          <v-col cols='12'>
            <p class='body-text text-center'>
              <span class='text-h5'>-</span>
              <br>
              Quel joli cadeau, à offrir ou à s’offrir,
              <br>
              une illustration faite rien que pour vous
            </p>
          </v-col>

          <!-- BTN -->
          <v-col cols='12'
                 class='buttons-container mt-4'>
            <router-link to='/categories/7'
                         class='text-decoration-none d-flex justify-center'>
              <v-btn text
                     elevation='0'
                     color='white'
                     class='buttons'
                     outlined>
                En savoir plus
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- CREA’FRICHE -->
    <v-row class='mb-10 mt-10 ma-0'>
      <v-spacer />
      <!-- IMG -->
      <v-col cols='12'
             md='4'
             class='pa-0'>
        <router-link to='/products/650'
                     class='text-decoration-none'
                     style='width: 100%'>
          <v-img :src='require(`@/assets/home_page/creafriche.jpg`)'
                 aspect-ratio='1'
                 contain />
        </router-link>
      </v-col>
      <!-- Texte -->
      <v-col cols='12'
             md='4'
             class='d-flex align-center atelier pt-10 pb-10'>
        <v-row>
          <!-- Titre -->
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column'>
            <v-img :src='require(`@/assets/home_page/house_ico.png`)'
                   width='100' />
            <span class='black--text'>CREA’FRICHE</span>
          </v-col>

          <!-- Texte -->
          <v-col cols='12'>
            <p class='body-text text-center'>
              <span class='text-h5'>-</span>
              <br>
              Réservez ici votre atelier
              <br>
              aquarelle d’automne,
              <br>
              le mardi 15 octobre
              <br>
              à La Friche / Saint-André-lez-Lille
            </p>
          </v-col>

          <!-- BTN -->
          <v-col cols='12'
                 class='buttons-container mt-4'>
            <router-link to='/products/650'
                         class='text-decoration-none d-flex justify-center'>
              <v-btn text
                     elevation='0'
                     color='black'
                     class='buttons'
                     outlined>
                En savoir plus
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- Carousel -->
    <v-row class='mt-10 mb-10 ma-0'>
      <v-spacer />
      <v-col cols='10'>
        <v-slide-group>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_1/1.jpg`)'
                   max-width='180'
                   max-height='180'
                   contain />
          </v-slide-item>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_1/2.jpg`)'
                   max-width='180'
                   max-height='180'
                   contain />
          </v-slide-item>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_1/3.jpg`)'
                   max-width='180'
                   max-height='180'
                   contain />
          </v-slide-item>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_1/4.jpg`)'
                   max-width='180'
                   max-height='180'
                   contain />
          </v-slide-item>
        </v-slide-group>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- Carte Naissance -->
    <v-row class='birth mt-10 mb-10 ma-0'>
      <!-- Texte -->
      <v-col cols='12'
             md='4'
             class='white'>
        <v-row>
          <!-- Titre -->
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column mt-10'>
            <v-img :src='require(`@/assets/home_page/card_ico.png`)'
                   width='100' />
            <span>NAISSANCES</span>
          </v-col>

          <!-- Texte -->
          <v-col cols='12'>
            <p class='text-center body-text'>
              <span class='text-h5'>-</span>
              <br>
              <br>
              Faire-part,
              <br>
              cartes étapes,
              <br>
              cartes première année,
              <br>
              affiches personnalisables...
              <br>
              <br>
              Bienvenue dans un univers
              <br>
              de douceur et de poésie
            </p>
          </v-col>

          <!-- BTN -->
          <v-col cols='12'
                 class='d-flex justify-center mt-2 mb-10'>
            <router-link to='/categories/22'
                         class='text-decoration-none'>
              <v-btn text
                     elevation='0'
                     outlined>
                découvrir
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <!-- IMG -->
      <v-col cols='12'
             md='4'
             class='pa-0'>
        <v-img :src='require(`@/assets/home_page/birth_fp.jpg`)'
               aspect-ratio='1'
               contain />
      </v-col>
    </v-row>

    <!-- Carousel -->
    <v-row class='mt-10 mb-10 ma-0'>
      <v-spacer />
      <v-col cols='10'>
        <v-slide-group>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_2/1.jpg`)'
                   max-width='180'
                   max-height='180'
                   contain />
          </v-slide-item>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_2/2.jpg`)'
                   max-width='180'
                   max-height='180'
                   contain />
          </v-slide-item>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_2/3.jpg`)'
                   max-width='180'
                   max-height='180'
                   contain />
          </v-slide-item>
          <v-slide-item class='ml-5 mr-5 img-resize'>
            <v-img :src='require(`@/assets/home_page/carrousel_2/4.jpg`)'
                   max-width='180'
                   max-height='180'
                   contain />
          </v-slide-item>
        </v-slide-group>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- ABOUT ME -->
    <v-row class='mt-10 mb-10 ma-0'>
      <v-spacer />
      <!-- IMG -->
      <v-col cols='12'
             md='4'>
        <v-img :src='require(`@/assets/home_page/about_me.jpg`)'
               max-width='600' />
      </v-col>
      <!-- Texte -->
      <v-col cols='12'
             md='4'
             class='white'>
        <v-row>
          <!-- Titre -->
          <v-col cols='12'
                 class='body-title d-flex align-center flex-column'>
            <v-img :src='require(`@/assets/home_page/pen_ico.png`)'
                   width='100' />
            <span>HELLO</span>
          </v-col>

          <!-- Texte -->
          <v-col cols='12'>
            <p class='text-center body-text'>
              <span class='text-h5'>-</span>
              <br>
              “100% PERSONNALISABLE!”
              <br>
              <br>
              Je suis Marie, graphiste lilloise,
              <br>
              maman passionnée par l’illustration,
              <br>
              diplômée en arts appliqués et
              <br>
              communication depuiiis......bref :)
              <br>
              Je me suis lancée dans la création de faire-part
              <br>
              en 2016 et depuis mon travail s’est élargi
              <br>
              en déclinant mes illustrations sur plusieurs supports
              <br>
              (affiches, livres, packaging...)
              <br>
              <br>
              En étant sur ce site, vous êtes en contact direct
              <br>
              avec moi, ce qui facilite les échanges
              <br>
              et la personnalisation des créations.
              <br>
              N’hésitez pas à me poser toutes vos questions!
            </p>
          </v-col>

          <!-- BTN -->
          <v-col cols='12'
                 class='d-flex justify-center mt-2'>
            <router-link to='/infos/contact'
                         class='text-decoration-none'>
              <v-btn text
                     elevation='0'
                     outlined>
                Contact
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'MobilePage',
    props: {
      calendar: { type: Object, default: () => {} },
      carouselImages: { type: Array, default: () => [] }
    },
    data: () => ({
      calendarId: process.env.VUE_APP_CALENDAR_ID
    }),
  }
</script>

<style lang='scss' scoped>
  .home {
    background-color: #f3ebe2;
    div {
      padding-top: 0 !important;
    }
  }

  .calendar {
    background-color: white;
    div {
      padding-top: 0 !important;
    }
  }

  .calendar-color {
    background-color: #f2c8c9;
    color: black;
  }

  .calendar-title {
    color: #A45F3C;
    text-align: center;
    font-size: 1.5rem;
  }

  .illustration {
    background-color: #b58a64;
    color: white;
  }

  .atelier {
    background-color: #f0cfae;
    color: black;
  }

  .illustration-title {
    color: white;
    text-align: center;
    font-size: 1.5rem;
  }

  .birth {
    background-color: #c2c9d4;
  }

  .birth-title {
    //color: white;
    text-align: center;
    font-size: 2rem;
  }

  .no-padding {
    //padding-bottom: 0 !important;
    //padding-top: 0 !important;

    padding: 0 !important;
  }

  .padding-hack {
    padding-right: 0 !important;
  }
</style>

<!--<v-col cols='12'-->
<!--       class='birth-title'-->
<!--       style='color: #B77A5F;'>-->
<!--NOUVEAU !-->
<!--</v-col>-->
